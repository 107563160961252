import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import logoBlack from '../images/logo-black.svg';
import logoWhite from '../images/logo-white.svg';
import '../styles/header.scss';

const Header = ({ headElementRef }) => {
  const headerRef = useRef();

  const [isMobileMenuBtnActive, setMobileMenuBtnStatus] = useState(false);
  const [isMenuSolid, setMenuSolid] = useState(true);

  const handleMobileMenuBtnClick = () => {
    setMobileMenuBtnStatus(!isMobileMenuBtnActive);
  };

  const mobileMenuBtnClass = isMobileMenuBtnActive ? 'mobile-menu-active' : '';

  const setClasses = () => `header ${mobileMenuBtnClass} ${isMenuSolid ? 'solid' : 'blank'}`;

  useEffect(() => {
    let observer = null;
    if (headElementRef && headElementRef.current) {
      const intersectionCallback = element => {
        setMenuSolid(element[0].isIntersecting === false);
      };

      observer = new IntersectionObserver(intersectionCallback);

      observer.observe(headElementRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });

  useOnClickOutside(headerRef, () => setMobileMenuBtnStatus(false));

  return (
    <header className={setClasses()} ref={headerRef}>
      <div className="logo">
        <Link to="/">
          <img
            src={isMenuSolid && !isMobileMenuBtnActive ? logoBlack : logoWhite}
            title="Архитектурно студио Стоянови"
            alt="Архитектурно студио Стоянови"
            loading="eager"
          />
          <span>Архитектурно студио Стоянови</span>
        </Link>
      </div>
      <nav>
        <div
          onClick={handleMobileMenuBtnClick}
          onKeyDown={handleMobileMenuBtnClick}
          className="mobile-menu-btn"
          role="button"
          tabIndex="0"
        >
          &#9776;
        </div>
        <ul>
          <li>
            <Link to={'/проекти'} activeClassName="active">
              Проекти
            </Link>
          </li>
          <li>
            <Link to={'/за-нас'} activeClassName="active">
              За Нас
            </Link>
          </li>
          <li>
            <Link to={'/арт-дизайн'} activeClassName="active">
              Арт дизайн
            </Link>
          </li>
          <li>
            <Link to={'/графичен-дизайн'} activeClassName="active">
              Графичен дизайн
            </Link>
          </li>
          <li>
            <Link to={'/blog'} activeClassName="active">
              Blog
            </Link>
          </li>
          <li>
            <Link to={'/контакти'} activeClassName="active">
              Контакти
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
