import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { forUsPages } from '../data/data';
import '../styles/footer.scss';

const Footer = ({ ad }) => {
  const data = useStaticQuery(graphql`
    query {
      projects: allMarkdownRemark(
        filter: { frontmatter: { category: { eq: true } } }
        sort: { fields: [frontmatter___index], order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              index
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return (
    <Fragment>
      <footer className="footer">
        <div className="footer-column">
          <h3>Проекти</h3>
          <ul>
            {data.projects.edges.map(item => (
              <li key={item.node.id}>
                <Link to={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}>
                  {item.node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-column">
          <h3>За Нас</h3>
          <ul>
            {forUsPages.map(item => (
              <li key={item.id}>
                <Link to={item.slug}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-column">
          <h3>Информация</h3>
          <ul>
            <li>
              <Link to="/контакти">Контакти</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        {ad && (
          <div className="footer-column ad">
            <Img fluid={ad.childImageSharp.fluid}></Img>
          </div>
        )}
      </footer>
      <div className="copyright">
        &copy; {new Date().getFullYear()} All rights reserved. Created by:{' '}
        <a href="https://pantaley.com/" target="_blank" rel="noopener noreferrer">
          {' '}
          pantaley.com
        </a>
      </div>
    </Fragment>
  );
};

export default Footer;
