import React, { StrictMode } from 'react';
import { Helmet } from 'react-helmet';
import Header from './header';
import Footer from './footer';
import favicon from '../images/logo-black.svg';

import '../styles/layout.scss';
import 'normalize.css';

const Layout = ({ children, metaData, headElementRef, ad }) => {
  const title = metaData && metaData.title ? metaData.title : 'Архитектура, проектиране и интериор дизайн';
  const description =
    metaData && metaData.description
      ? metaData.description
      : 'Проектиране на обществени, жилищни, промишлени сгради, интериор и дизайн, тримерни визуализации и мултимедийно представяне на проектите';
  return (
    <StrictMode>
      <Helmet>
        <html lang="bg" />
        <title>{`${title} | Архитектурно студио Стоянови`}</title>
        <link rel="shortcut icon" href={favicon} type="image/svg" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />

        {/* <meta
            property="og:image"
            content={metaData.featuredImage ? metaData.featuredImage.publicURL : featuredImage}
          />

          <meta property="fb:app_id" content="389103081651332" /> */}

        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>
      <Header headElementRef={headElementRef} />
      {children}
      <Footer ad={ad} />
    </StrictMode>
  );
};

export default Layout;
