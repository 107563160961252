export const forUsPages = [
  { id: 'history', title: 'История на Архитектурното бюро', slug: '/за-нас/история' },
  { id: 'partners', title: 'Партньори', slug: '/за-нас/партньори' },
  { id: 'competitions', title: 'Конкурси', slug: '/за-нас/конкурси' },
  { id: 'awards', title: 'Награди', slug: '/за-нас/награди' },
  { id: 'albums', title: 'Албум проекти', slug: '/за-нас/албум-проекти' }
];

export const artDesign = [
  { id: 'architectureAccessoires', title: 'Architecture Accessoires', slug: '/арт-дизайн/architecture-accessoires' },
  { id: 'babyAccessoires', title: 'Baby Accessoires', slug: '/арт-дизайн/baby-accessoires' },
  { id: 'houseAccessoires', title: 'House Accessoires', slug: '/арт-дизайн/house-accessoires' },
  { id: 'easterInHouse', title: 'Великден вкъщи', slug: '/арт-дизайн/великден-вкъщи' },
  { id: 'cards', title: 'Картички', slug: '/арт-дизайн/картички' }
];

export const graphicDesign = [
  { id: 'posters', title: 'Плакати', slug: '/графичен-дизайн/плакати' },
  { id: 'archforum', title: 'Archforum', slug: '/графичен-дизайн/archforum' },
  { id: 'calendarMondi', title: 'Календар Монди', slug: '/графичен-дизайн/календар-монди' },
  { id: 'cards', title: 'Картички', slug: '/графичен-дизайн/картички' }
];

export const partners = [
  {
    name: 'Арх. Мария Стоянова',
    list: [
      '1958г. – родена в гр. Пловдив',
      '1981г. – завършва ВИАС специалност АРХИТЕКТУРА',
      '1982г. – започва работа в Проектантска организация Пловдив',
      '1988г. – работи в НИАПИ "Интерпроект" към Международната Академия по Архитектура',
      '1994г. – е собственик на ЕТ "Мария Стоянова АРХ" и "Архитектурно студио Стоянови" ЕООД'
    ]
  },
  {
    name: 'Арх. Володя Стоянов',
    list: [
      '1954г. – роден в гр. Луковит',
      '1980г. – завършва ВИАС специалност АРХИТЕКТУРА',
      '1981г. – започва работа като главен архитект на община Раковски',
      '1983г. – работи в Проектантска организация Пловдив',
      '1988г. – работи в НИАПИ "Интерпроект" към Международната Академия по Архитектура',
      '1994г. – собственик е на ЕТ "Володя Стоянов"',
      '2000г. – работи като главен архитект на район Централен в Пловдив',
      '2002г. – е на свободна практика'
    ]
  },
  {
    name: 'Арх. Чавдар Тенев',
    list: [
      '1957г. – роден в гр. Пловдив',
      '1982г. – завършва ВИАС специалност АРХИТЕКТУРА',
      '1982г. – започва работа в Община Пловдив- V кметство',
      '1983г. – работи в Института към комбинат "Кофражна техника"',
      '1992г. – е съдружник във фирма "Арконт" СД',
      '1995г. – е назначен за зам.кмет и главен архитект в район Западен, Община Пловдив',
      '1999г. – е кмет на раойн Централен, Община Пловдив',
      '2002г. – е архитект на свободна практика.',
      '2004г. – е и съсобственик в "Макети" ООД'
    ]
  },
  {
    name: 'Арх. Екатерина Генева',
    list: [
      '1954г. – родена в гр. Харманли',
      '1977г. – завършва ВИАС специалност АРХИТЕКТУРА',
      '1977г. – започва работа в Проектантска организация Пловдив',
      '1988г. – работи в НИАПИ "Интерпроект" към Международната Академия по Архитектура',
      '1994г. – е собственик на "Архитектурно бюро Генева" ЕООД'
    ]
  },
  {
    name: 'Арх. Величко Куртев',
    list: [
      '1952г. – роден в гр. Пловдив',
      '1976г. – заврършва ВИАС специалност архитектура',
      '1976г. – започва работа в Проектантска организация Пловдив',
      '1988г. – работи в НИАПИ "Интерпроект" към Международната Академия по Архитектура',
      '1993г. – е собственик на ЕТ "Арх. Величко Куртев"'
    ]
  }
];

export const contactDetails = [
  {
    name: 'Архитект Мария Стоянова',
    mobile: '+359 887 917 971',
    email: 'maria@archstoyanovi.com',
    address: 'Пловдив, ул. Петко Каравелов 21, 5 етаж'
  },
  {
    name: 'Архитект Володя Стоянов',
    mobile: '+359 888 776 451',
    email: 'volodia_stoyanov@abv.bg',
    address: 'Пловдив, ул. Петко Каравелов 21, 5 етаж'
  },
  {
    name: 'Архитект Чавдар Тенев',
    mobile: '+359 888 218 131',
    email: 'chavdartenev@yahoo.com',
    address: 'Пловдив, ул. Петко Каравелов 21, 5 етаж'
  },
  {
    name: 'Архитект Екатерина Генева',
    mobile: '+359 889 915 409',
    email: 'ekaterinageneva@yahoo.com',
    address: 'Пловдив, ул. Петко Каравелов 21, 5 етаж'
  },
  {
    name: 'Архитект Величко Куртев',
    mobile: '+359 888 515 874',
    email: 'arch_kurtev@yahoo.com',
    address: 'Пловдив, ул. Петко Каравелов 21, 5 етаж'
  }
];

export const homePageSlides = [
  // {
  //   name: 'Интериори на банкови офиси на ОББ',
  //   image: 'ubb.jpg',
  //   video: 'ubb.webm',
  //   url: '/интериор-и-дизайн/интериорни-решения-и-дизайн-на-пространства-за-иновации-в-сграда-на-обб-милениум-център'
  // },
  {
    name: 'Новотел Пловдив',
    image: 'Novotel.jpg',
    url: '/обществени-сгради/новотел-пловдив'
  },
  {
    name: 'Партиен дом Пловдив',
    image: 'Partien-Dom.jpg',
    url: '/обществени-сгради/партиен-дом-пловдив'
  },
  {
    name: 'Хотел Мирена Пловдив',
    image: 'Hotel-Mirena.jpg',
    url: '/обществени-сгради/хотел-мирена-и-търговски-комплекс'
  },
  {
    name: 'Миг Инженеринг Пловдив',
    image: 'mig-engineering.jpg',
    url: '/обществени-сгради/търговско-административен-център-миг-инженеринг'
  },
  {
    name: 'Уникредит Булбанк Пловдив',
    image: 'Unicredit.jpg',
    url: '/обществени-сгради/банка-уникредит-булбанк/'
  },
  {
    name: 'Монди Стамболийски ЕАД',
    image: 'Mondi-Stamboliyski.jpg',
    url: '/обществени-сгради/административни-сгради-монди'
  },
  {
    name: 'Монди Стамболийски ЕАД',
    image: 'Mondi-Stamboliyski-stairs.jpg',
    url: '/обществени-сгради/административни-сгради-монди'
  },
  {
    name: 'МБАЛ Св. Панталеймон Плевен',
    image: 'mbal-Pantaleon.jpg',
    url: '/обществени-сгради/мбал-св-панталеймон-в-плевен'
  }
];
